/* eslint-disable no-nested-ternary, accessor-pairs, no-underscore-dangle, max-statements, id-length, max-classes-per-file, import/no-unassigned-import */
import '@webcomponents/custom-elements';

const clamp = (min: number, max: number, value: number): number =>
  value < min ? min : value > max ? max : value;

const getMidpoint = (a: Point, b: Point): Point => new Point((a.x + b.x) / 2, (a.y + b.y) / 2);

const getDistance = (a: Point, b: Point): number => Math.sqrt((b.x - a.x) ** 2 + (b.y - a.y) ** 2);

class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

class PinchTouch extends HTMLElement {
  currentZoom: number;
  zoomStrength: number;
  previousPosition: Point;
  prevDistance: number;
  previousTouches: TouchList | null;
  pointerDown: boolean;
  disableTouchMove: boolean;
  currentTransformation: Point;
  maxZoom: number;
  doubleClickDelay: number;
  firstClickTime: number;
  lastZoomTime: number;
  disableZoomTimeout: NodeJS.Timeout;
  lastZoomClickPosition: Point;
  singlePageMode: string;
  currentPageView: string;
  positioningEl: HTMLElement;

  constructor() {
    super();
    this.currentZoom = 1;
    this.zoomStrength = 0.03;
    this.previousPosition = new Point(0, 0);
    this.prevDistance = 0;
    this.previousTouches = null;
    this.pointerDown = false;
    this.disableTouchMove = false;
    this.currentTransformation = new Point(0, 0);
    this.maxZoom = 2;
    this.doubleClickDelay = 400;
    this.firstClickTime = 0;
    this.lastZoomTime = 0;
    this.disableZoomTimeout = null;
    this.lastZoomClickPosition = new Point(0, 0);
    this.singlePageMode = '';
    this.currentPageView = '';
  }

  static get observedAttributes() {
    return ['page-view', 'single-page-mode', 'zoom'];
  }

  connectedCallback() {
    this._setupListeners();
    this.positioningEl = <HTMLElement>this.firstElementChild;
  }

  disconnectedCallback() {
    this._removeListeners();
  }

  _setupListeners() {
    this.addEventListener('click', this._handleDoubleClick);
    this.addEventListener('pointerdown', this._pointerDown);
    this.addEventListener('pointerup', this._pointerUp);
    this.addEventListener('pointermove', this._pointerMove);
    this.addEventListener('touchstart', this._handleTouchStart, { passive: true });
    this.addEventListener('touchend', this._handleTouchEnd);
    this.addEventListener('touchmove', this._handleTouchMove);
    this.addEventListener('wheel', this._handleWheel);

    // Stop IE9 from breaking. temp fix.
    document.body.classList.add('mod-contain');
    document.body.classList.add('mod-zoom-enabled');

    const zoomEl = document.querySelector('#divPtiContainer_zoom');

    if (zoomEl) {
      zoomEl.classList.add('mod-zoom-in');
    }
  }

  _removeListeners() {
    this.removeEventListener('click', this._handleDoubleClick);
    this.removeEventListener('pointerdown', this._pointerDown);
    this.removeEventListener('pointerup', this._pointerUp);
    this.removeEventListener('pointermove', this._pointerMove);
    this.removeEventListener('touchstart', this._handleTouchStart);
    this.removeEventListener('touchend', this._handleTouchEnd);
    this.removeEventListener('touchmove', this._handleTouchMove);
    this.removeEventListener('wheel', this._handleWheel);

    if (document.body) {
      document.body.classList.remove('mod-contain');
      document.body.classList.remove('mod-zoom-enabled');

      const zoomEl = document.querySelector('#divPtiContainer_zoom');

      if (zoomEl) {
        zoomEl.classList.remove('mod-zoom-in');
      }
    }
  }

  /**
   *
   * @param {'page-view'|'zoom'|'single-page-mode'} name
   * @param {string} oldValue
   * @param {string} newValue
   * @returns {void}
   */
  attributeChangedCallback(
    name: 'page-view' | 'zoom' | 'single-page-mode',
    _oldValue: string,
    newValue: string
  ): void {
    switch (name) {
      case 'page-view': {
        this.currentPageView = newValue;
        const isZoomEnabled =
          this.currentPageView === 'double' || this.singlePageMode === 'width and height';

        if (isZoomEnabled) {
          this._setupListeners();
        } else {
          this._removeListeners();
        }

        return;
      }

      case 'zoom':
        this._setZoom(parseInt(newValue));

        return;

      case 'single-page-mode':
        this.singlePageMode = newValue;
    }
  }

  /**
   * @param {PointerEvent} e
   */
  _pointerDown(e: PointerEvent) {
    this.previousPosition = new Point(e.clientX, e.clientY);
    this.pointerDown = true;
  }

  _pointerUp() {
    this.pointerDown = false;
  }

  /**
   * @param {PointerEvent} e
   */
  _pointerMove(e: PointerEvent) {
    e.preventDefault();
    const { clientX, clientY } = e;
    const { currentTransformation, disableTouchMove, pointerDown, currentZoom } = this;

    if (disableTouchMove) {
      return;
    }

    if (pointerDown && currentZoom > 1) {
      const deltaX = this.previousPosition.x - e.clientX;
      const deltaY = this.previousPosition.y - e.clientY;
      const newX = Math.floor(currentTransformation.x + deltaX);
      const newY = Math.floor(currentTransformation.y + deltaY);

      this.previousPosition = new Point(clientX, clientY);
      this._setTransformOrigin(new Point(newX, newY));
    }
  }

  /**
   * @param {MouseEvent} e
   */
  _handleDoubleClick(e: MouseEvent) {
    const distanceThreshold = 30;
    const now = performance.now();
    const isInZoomCoolDown = now - this.lastZoomTime < this.doubleClickDelay;
    const doubleClickedFastEnough = now - this.firstClickTime < this.doubleClickDelay;
    const positionClicked = new Point(e.clientX, e.clientY);
    const diffX = Math.abs(this.lastZoomClickPosition.x - e.clientX);
    const diffY = Math.abs(this.lastZoomClickPosition.y - e.clientY);
    const clicksCloseEnough = diffX < distanceThreshold || diffY < distanceThreshold;

    this.lastZoomClickPosition = positionClicked;

    if (doubleClickedFastEnough && !isInZoomCoolDown && clicksCloseEnough) {
      this._setTransformOrigin(positionClicked);
      this._setZoom(this.currentZoom === 1 ? 2 : 1, true);
      this.firstClickTime = 0;
      this.lastZoomTime = now;
    } else {
      this.firstClickTime = now;
    }
  }

  /**
   * @param {WheelEvent} e
   */
  _handleWheel(e: WheelEvent) {
    e.preventDefault();
    e.stopPropagation();

    const { deltaY } = e;
    const zoom =
      deltaY < 0
        ? (this.currentZoom = this.currentZoom + this.currentZoom * this.zoomStrength)
        : (this.currentZoom = this.currentZoom - this.currentZoom * this.zoomStrength);

    this._setTransformOrigin(new Point(e.clientX, e.clientY));
    this._setZoom(zoom);
  }

  /**
   * @param {TouchEvent} e
   */
  _handleTouchStart(e: TouchEvent) {
    this.disableTouchMove = e.touches.length > 1;

    if (!e.touches || e.touches.length !== 2) {
      return;
    }

    if (!this.previousTouches) {
      const [touchOne, touchTwo] = e.touches;
      const newMidpoint = getMidpoint(
        new Point(touchOne.clientX, touchOne.clientY),
        new Point(touchTwo.clientX, touchTwo.clientY)
      );

      this._setTransformOrigin(newMidpoint);
    }

    this.previousTouches = e.touches;
  }

  /**
   * @param {TouchEvent} e
   */
  _handleTouchMove(e: TouchEvent) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.disableTouchMove = e.touches.length > 1;

    if (!e.touches || (e.touches.length !== 2 && this.positioningEl)) {
      return;
    }

    const [touchOne, touchTwo] = e.touches;
    const [previousTouchOne, previousTouchTwo] = this.previousTouches ?? e.touches;

    // For calculating panning movement
    const prevMidpoint = getMidpoint(
      new Point(previousTouchOne.clientX, previousTouchOne.clientY),
      new Point(previousTouchTwo.clientX, previousTouchTwo.clientY)
    );
    const newMidpoint = getMidpoint(
      new Point(touchOne.clientX, touchOne.clientY),
      new Point(touchTwo.clientX, touchTwo.clientY)
    );

    // Calculate the desired change in scale
    const prevDistance = getDistance(
      new Point(previousTouchOne.clientX, previousTouchOne.clientY),
      new Point(previousTouchTwo.clientX, previousTouchTwo.clientY)
    );
    const newDistance = getDistance(
      new Point(touchOne.clientX, touchOne.clientY),
      new Point(touchTwo.clientX, touchTwo.clientY)
    );

    const scaleDiff = newDistance / prevDistance;
    const newScale = this.currentZoom * scaleDiff;

    const deltaX = prevMidpoint.x - newMidpoint.x;
    const deltaY = prevMidpoint.y - newMidpoint.y;

    this._setTransformOrigin(
      new Point(this.currentTransformation.x + deltaX, this.currentTransformation.y + deltaY)
    );

    this._setZoom(newScale);
    this.previousTouches = e.touches;
  }

  /**
   * @param {TouchEvent} e
   */
  _handleTouchEnd(e: TouchEvent) {
    this.disableTouchMove = e.touches.length !== 0;
  }

  /**
   * @param {number} zoom
   * @param {boolean} [transition]
   * @returns {void}
   */
  _setZoom(zoom: number, transition: boolean = false): void {
    const zoomClasses = ['mod-zoom-in', 'mod-zoom-out'];
    const [zoomClassAdd, zoomClassRemove] = zoom > 1 ? zoomClasses.reverse() : zoomClasses;

    this.currentZoom = clamp(1, this.maxZoom, zoom);

    if (this.positioningEl) {
      this.positioningEl.style.transition = transition ? 'transform 0.25s' : '';
      this.positioningEl.style.transform = `scale(${this.currentZoom})`;

      if (this.singlePageMode !== 'width and height' && this.currentPageView === 'single') {
        this.positioningEl.classList.remove(zoomClassAdd);
        this.positioningEl.classList.remove(zoomClassRemove);

        return;
      }

      this.positioningEl.classList.add(zoomClassAdd);
      this.positioningEl.classList.remove(zoomClassRemove);

      // Prevent the page from swiping to the next / prev page when we've just zoomed out
      if (this.currentZoom === 1) {
        if (this.disableZoomTimeout === null) {
          window.disableSwipe = true;

          this.disableZoomTimeout = setTimeout(() => {
            window.disableSwipe = false;
            this.disableZoomTimeout = null;
          }, 400);
        }
      }
    }
  }

  /**
   * @param {Point} point
   * @returns {void}
   */
  _setTransformOrigin({ x, y }: Point): void {
    if (this.positioningEl) {
      const boundingRect = this.positioningEl.getBoundingClientRect();
      const { height } = boundingRect;
      const { width } = boundingRect;
      const clampedWidth = clamp(0, width, x);
      const clampedHeight = clamp(0, height, y);

      this.positioningEl.style.transformOrigin = `${clampedWidth}px ${clampedHeight}px`;
      this.currentTransformation = new Point(x, y);
    }
  }
}

window.customElements.define('pinch-touch', PinchTouch);
